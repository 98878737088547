<template>
  <div>
    <navigation msg="企业信息"></navigation>
    <div class="part1">
      <div class="flex">
        <div>
          <img style="width:1.04rem;height:1.04rem" src="../../assets/mycompany.png" alt="">
        </div>
        <div style="margin-left:0.2rem;">
          <div class="title">公司名称</div>
          <div style="margin-top:0.2rem;" class="name">{{companyInfo.company == ""  ? "暂无" : companyInfo.company}}</div>
        </div> 
      </div>
      <div style="margin-top:0.2rem;">
        <div class="flex2">
          <div >
            <div class="title">法定代表人</div>
            <div class="name">{{basic.FRNAME == "" ? "暂无" : basic.FRNAME}}</div>
          </div>
          <div >
            <div class="title">注册资本</div>
            <div class="name">{{basic.REGCAP == "" ? "暂无" : basic.REGCAP}}</div>
          </div>
           <div style="margin-right:0.6rem">
            <div class="title">成立日期</div>
            <div class="name">{{basic.ESDATE == "" ? "暂无" : basic.ESDATE}}</div>
          </div>
        </div>
        <div style="display:flex;">
          <div >
            <div class="title">经营状态</div>
            <div class="name">{{basic.ENTSTATUS == "" ? "暂无" :  basic.ENTSTATUS}}</div>
          </div>
          <div style="margin-left:0.40rem;">
            <div class="title">企业类型</div>
            <div class="name">{{basic.ENTTYPE == "" ? "暂无" : basic.ENTTYPE}}</div>
          </div>
        </div>

      </div>
    </div>

     <div class="part2">
        <div class="title">英文名</div>
        <div style="margin-top:0.2rem;" >{{basic.ENTNAMEENG == "" ? "暂无" : basic.ENTNAMEENG}}</div>
      </div>

      <div class="part3">
         <div style="display:flex;">
            <div class="pitem">
            <div class="title">统一社会信用社代码</div>
            <div class="name1">{{basic.ENTNAMEENG == "" ? "暂无" : basic.CREDITCODE}}</div>
          </div>
          <div class="pitem">
            <div class="title">纳税人识别号</div>
            <div class="name1">913100007178594587A</div>
          </div>
         </div>
         
      
       <div style="display:flex; ">
          <div class="pitem">
          <div class="title">工商注册号</div>
          <div class="name1">{{basic.REGNO == "" ? "暂无" : basic.REGNO }}</div>
        </div>
        <div class="pitem">
          <div class="title">企业组织机构代码</div>
          <div class="name1">{{basic.ORGCODES == "" ? "暂无" : basic.ORGCODES}}</div>
        </div>
       </div>

       <div style="display:flex;">
          <div class="pitem">
          <div class="title">进出口企业代码</div>
          <div class="name1">3100004554</div>
        </div>
        <div class="pitem">
          <div class="title">纳税人资质</div>
          <div class="name1">-</div>
        </div>
       </div>
       
      </div>

      <div class="part4" >
        <div class="title">经营范围</div>
        <div style="line-height:0.4rem;" class="name1">{{basic.ZSOPSCOPE == "" ? "暂无" : basic.ZSOPSCOPE }}</div>
      </div>

      <div class="part5">
        <div style="margin-bottom:0.5rem">
          <div class="title">经营期限</div>
          <div class="name1">{{basic.OPFROM == "" ? "暂无" : basic.OPFROM}} 至 {{basic.OPTO == "" ? "暂无" : basic.OPTO}}</div>
        </div>
        <div style="margin-bottom:0.5rem">
           <div class="title">核准日期</div>
          <div class="name1">{{basic.APPRDATE == "" ? "暂无" : basic.APPRDATE}}</div>
        </div>
        <div style="margin-bottom:0.5rem">
            <div class="title">登记机关</div>
            <div class="name1">{{basic.REGORG==""  ? "暂无" : basic.REGORG}}</div>
        </div> 
      </div>

      <div class="part6">
        <div style="margin-bottom:0.5rem">
          <div class="title">电话</div>
          <div class="name1">{{companyInfo.tel == "" ? "暂无" : companyInfo.tel}}</div>
        </div>
        <div style="margin-bottom:0.5rem">
           <div class="title">官网</div>
          <div class="name1">暂无</div>
        </div>
        <div style="margin-bottom:0.5rem">
           <div class="title">邮箱</div>
          <div class="name1">{{basic.EMAIL == "" ? "暂无" : basic.EMAIL}}</div>
        </div>
        <div style="margin-bottom:0.5rem">
           <div class="title">企业地址</div>
          <div class="name1">{{companyInfo.address == "" ? "暂无" : companyInfo.address}}</div>
        </div>
      </div>

      <div class="part7">
        <div class="p1 title">
          <div>变更记录</div>
          <div>2020-10-12</div>
        </div>
        <div v-if="ALTER.length>0">
          <div class="t1">01 经营范围变更</div>
          <div class="bg1">
            <div class="bg">变更前</div>
            <div class="c1">计算机软硬件技术开发，计算机网络系统的设计，系统集成，网络技术开发，接受金融机构委托从事金融业务外包服务，商务信息咨询服务（投资咨询除外），通过互联网销售：软件。</div>
          </div>
          <div class="bg1">
            <div class="bg">变更后</div>
            <div class="c1">计算机软硬件技术开发，计算机网络系统的设计，系统集成，网络技术开发，接受金融机构委托从事金融业务外包服务，商务信息咨询服务（投资咨询除外），通过互联网销售：软件。</div>
          </div>
        </div>
        <div v-else class="name">暂无</div>
      </div>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
// import { Toast } from "vant";
export default {
  data(){
    return {
      basic:{},
      entinv:[],
      companyInfo:{},
      ALTER:[]
    }
  },
  components:{
    navigation
  },
  mounted(){
    // let id = this.$route.query.id
    this.$http.post("/user/v1/personal_info/moreCompanyInfo",{reqType:"moreCompanyInfo",company_id:2494})
    .then((res)=>{
      res = JSON.parse(res.data)
      let {BASIC,ENTINV,ALTER } = res.data.moreCompanyInfo
      let {companyInfo} =  res.data
      this.basic = BASIC
      this.entinv = ENTINV
      this.companyInfo = companyInfo
      this.ALTER = ALTER

      // console.log(res)
    })
    .catch((e)=>{console.log(e)})
  },
}
</script>
<style scoped>
.flex2{
  display:flex;
  justify-content: space-between;
}
.c1{
  font-size: 0.26rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
}
.bg{
  font-size: 0.26rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #777777;
margin-bottom:0.2rem;
}
.part1{

background: #FFFFFF;
border: 0.01rem solid #FFFFFF;
margin-top:0.18rem;
padding:0.3rem 0.6rem;
}
.part2{
  margin-top:0.18rem;
  /* height: 1rem; */
background: #FFFFFF;
padding:0.4rem 0.62rem;
}
.part3{
margin-top:0.18rem;
background: #FFFFFF;
border: 0.01rem solid #FFFFFF;
/* display:flex;
flex-wrap: wrap; */
padding:0 0.62rem;
}
.part4{
  padding:0.62rem;
  background: #FFFFFF;
  margin-top:0.18rem;
}
.part5{
  /* height: 3rem; */
   margin-top:0.18rem;
  background: #FFFFFF;
  padding:0.32rem 0.62rem;
}
.part6{
    margin-top:0.18rem;
    /* height: 4rem; */
    background: #FFFFFF;
    padding:0.32rem 0.62rem;
}
.part7{
    margin-top:0.18rem;
    padding:0.62rem;
    background: #FFFFFF;
}
.pitem{
  width:50%;
  margin:0.3rem 0;
}

.flex{
  display:flex;
  flex-wrap: wrap;
}
.title{
  font-size: 0.26rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #777777;
/* margin-bottom:0.rem; */
}
.name{
margin-top:0.05rem;
font-size: 0.27rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
margin-bottom:0.5rem
}
.name1{
   font-size: 0.27rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
margin-top:0.1rem;
}
.p1{
  display:flex;
  justify-content: space-between;
}
.t1{
  font-size: 0.26rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
padding:0.2rem 0;
}
.bg1{
  background:#F7F7F7;
  margin-bottom:0.2rem;
  padding:0.2rem;
}

</style>